<template>
    <div class="message-component" :class="type" v-html="$t(msg)" @click="emmitClick()"></div>
</template>

<script>
export default {
    name: 'Message',
    props: {
        type: String,
        msg: String
    },
    methods: {
        emmitClick() {
            this.$emit('clicked')
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.message-component {
    display: block;
    width: 100%;
    max-width: 500px;
    margin: 10px auto;
    border-radius: 3px;
    padding: 15px 20px;
    background-color: #c8e4f5;
    border-left: 5px solid #84a9bf;
    color: #333;

    b.bolder {
        font-family: Speedee-bd !important;
    }

    &.error {
        background: #ffb0b0;
        color: #802828;
        border-left-color: #da7575;
    }

    &.ok {
        background: #c0fdd3;
        color: #286d3d;
        border-left-color: #76b389;
    }
}
</style>
